<script>
  import Navigation from './components/Navigation.svelte';
  import Hero from './components/Hero.svelte';
  import About from './components/About.svelte';
  import Rooms from './components/Rooms.svelte';
  import Dining from './components/Dining.svelte';
  import Services from './components/Services.svelte';
  import Contact from './components/Contact.svelte';
  import Footer from './components/Footer.svelte';
</script>

<div
  style="max-width: 1400px;"
  class="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8 mx-auto"
>
  <Navigation />

  <Hero />
  <About />
  <Rooms />
  <Dining />
  <Services />
  <Contact />

  <Footer />
</div>
