<script>
  function handleBooking() {
    window.open('https://book.nightsbridge.com/37468');
  }
</script>

<section
  class="w-full px-4 py-8 relative mt-3"
  style="height: 82vh"
>
  <img
    src="images/dining/staircase.jpg"
    alt="Luxury retreat view"
    class="absolute inset-0 w-full h-full object-cover rounded-3xl"
  />
  <div
    class="absolute inset-0 rounded-3xl"
    style="background: linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.3))"
  ></div>
  <div
    class="absolute flex flex-col items-start bottom-0 right-0 left-0 px-12 py-16 md:px-24 text-white z-10"
  >
    <h1
      class="text-2xl max-w-3xl md:text-6xl font-display mb-2"
    >
      Experience Luxury in the Heart of Gunhill, Harare
    </h1>

    <a
      style="text-decoration: none;"
      href="https://book.nightsbridge.com/37468"
      class="rounded-full border-none no-underline flex align-center gap-2 bg-white px-4 py-4 text-black z-10"
    >
      Book now
    </a>
  </div>
  <div
    class="absolute rounded-3xl flex align-center gap-2 bg-black top-2 left-2 px-4 py-4 text-white z-10"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      color="white"
      fill="none"
    >
      <path
        d="M18.7185 10.7151C18.5258 10.8979 18.2682 11 18.0001 11C17.732 11 17.4744 10.8979 17.2817 10.7151C15.5167 9.03169 13.1515 7.15111 14.305 4.42085C14.9286 2.94462 16.4257 2 18.0001 2C19.5745 2 21.0715 2.94462 21.6952 4.42085C22.8472 7.14767 20.4878 9.03749 18.7185 10.7151Z"
        stroke="currentColor"
        stroke-width="1.5"
      ></path>
      <path
        d="M18 6H18.009"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <circle
        cx="5"
        cy="19"
        r="3"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></circle>
      <path
        d="M11 7H9.5C7.567 7 6 8.34315 6 10C6 11.6569 7.567 13 9.5 13H12.5C14.433 13 16 14.3431 16 16C16 17.6569 14.433 19 12.5 19H11"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
    <p class="font-display">
      4 Gunhill Avenue, Gunhill, Harare
    </p>
  </div>
</section>
