<script>
  const contactInfo = {
    phone: '+263 719 46 9089',
    whatsapp: '263719469089',
    email: 'gunhillaveguesthouse@gmail.com',
    facebook:
      'https://www.facebook.com/profile.php?id=61551733025096',
  };
</script>

<section
  id="contact"
  class="bg-gray-50px-4 sm:px-6 lg:px-8 pb-16 pt-8 md:pt-24"
>
  <div class="max-w-xl mx-auto">
    <h2
      style="color: #4C2617;"
      class="text-3xl mb-6 md:text-5xl font-display text-stone-900 text-center"
    >
      Get in Touch
    </h2>

    <!-- Contact Cards Grid -->
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <!-- Phone -->
      <a
        href="{`tel:${contactInfo.phone}`}"
        class="bg-white p-6 rounded-full shadow-sm hover:shadow-md transition-shadow flex items-center gap-4"
      >
        <div class="bg-gray-50 p-3 rounded-2xl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 h-6"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path
              d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
            ></path>
          </svg>
        </div>
        <div>
          <h3 class="font-medium mb-1">Phone</h3>
          <p class="text-gray-600">{contactInfo.phone}</p>
        </div>
      </a>

      <!-- WhatsApp -->
      <a
        href="{`https://wa.me/${contactInfo.whatsapp}`}"
        target="_blank"
        rel="noopener noreferrer"
        class="bg-white p-6 rounded-full shadow-sm hover:shadow-md transition-shadow flex items-center gap-4"
      >
        <div class="bg-gray-50 p-3 rounded-2xl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 h-6"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM16.64 15.55C16.4 16.31 15.3 16.96 14.47 17.23C13.93 17.41 13.24 17.54 11.43 16.82C9.11 15.88 7.62 13.54 7.5 13.38C7.39 13.22 6.54 12.08 6.54 10.89C6.54 9.69 7.14 9.12 7.39 8.85C7.64 8.58 7.93 8.5 8.1 8.5C8.27 8.5 8.44 8.5 8.59 8.51C8.74 8.52 8.97 8.46 9.19 9.01C9.42 9.57 9.97 10.77 10.02 10.87C10.07 10.97 10.11 11.1 10.04 11.25C9.97 11.4 9.94 11.5 9.84 11.62C9.74 11.74 9.63 11.89 9.54 11.98C9.44 12.08 9.33 12.19 9.45 12.42C9.57 12.65 10.12 13.55 10.93 14.27C11.96 15.19 12.81 15.46 13.07 15.56C13.33 15.66 13.46 15.63 13.59 15.48C13.72 15.33 14.24 14.73 14.39 14.47C14.54 14.21 14.69 14.25 14.89 14.32C15.09 14.39 16.28 14.98 16.5 15.09C16.72 15.2 16.87 15.25 16.92 15.33C16.97 15.42 16.97 16.09 16.64 15.55Z"
            ></path>
          </svg>
        </div>
        <div>
          <h3 class="font-medium mb-1">WhatsApp</h3>
          <p class="text-gray-600">Message us</p>
        </div>
      </a>

      <!-- Email -->
      <a
        href="{`mailto:${contactInfo.email}`}"
        class="bg-white p-6 rounded-full shadow-sm hover:shadow-md transition-shadow flex items-center gap-4"
      >
        <div class="bg-gray-50 p-3 rounded-2xl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 h-6"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path
              d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
            ></path>
            <polyline points="22,6 12,13 2,6"></polyline>
          </svg>
        </div>
        <div>
          <h3 class="font-medium mb-1">Email</h3>
          <p class="text-gray-600">{contactInfo.email}</p>
        </div>
      </a>

      <!-- Facebook -->
      <a
        href="{contactInfo.facebook}"
        target="_blank"
        rel="noopener noreferrer"
        class="bg-white p-6 rounded-full shadow-sm hover:shadow-md transition-shadow flex items-center gap-4"
      >
        <div class="bg-gray-50 p-3 rounded-2xl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 h-6"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path
              d="M18.77,7.46H14.55V10.24H18.77V14.47H14.55V27.93H9.28V14.47H6.1V10.24H9.28V7.46C9.28,5.91 9.69,4.39 10.5,3.33C11.3,2.27 12.5,1.59 13.89,1.34C14.5,1.23 15.2,1.16 15.89,1.16H18.77V7.46Z"
            ></path>
          </svg>
        </div>
        <div>
          <h3 class="font-medium mb-1">Facebook</h3>
          <p class="text-gray-600">Follow us</p>
        </div>
      </a>
    </div>

    <!-- Footer -->
    <footer class="mt-16 pt-8 border-t border-gray-200">
      <div class="text-center text-gray-600">
        <p class="mb-4">
          <b>4 Gunhill Avenue, Gunhill, Harare</b>
        </p>
        <p class="text-sm">
          © {new Date().getFullYear()} Gunhill Avenue Guest
          House. All rights reserved.
        </p>
      </div>
    </footer>
  </div>
</section>
