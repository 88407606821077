<script>
  import { fade, fly } from 'svelte/transition';

  let activeCategory = 'mains';

  const menuItems = {
    mains: [
      {
        name: 'Biscuit Trifle',
        image: '/images/dining/trifle.jpg',
        description:
          'Layered dessert with crushed biscuits, cream, and seasonal fruits',
      },
      {
        name: 'Mixed Grill',
        image: '/images/dining/grill.jpeg',
        description:
          'Selection of grilled meats served with seasonal vegetables',
      },

      {
        name: 'Stir Fry',
        image: '/images/dining/stir-fry.jpg',
        description:
          'Fresh vegetables and your choice of protein in a savory sauce',
      },
      {
        name: 'Full Bream',
        image: '/images/dining/bream.jpg',
        description:
          'Whole sea bream grilled to perfection with herbs and lemon',
      },
    ],
  };
</script>

<!-- Marketing Column -->

<div
  id="dining"
  class="relative space-y-16 md:overflow-hidden"
>
  <div
    class="bg-black text-white shadow-sm rounded-3xl overflow-hidden"
  >
    <div
      class="grid lg:grid-cols-12 bg-white rounded-3xl text-black m-4"
    >
      <!-- Text Column (8/12) -->
      <div
        class="lg:col-span-7 p-4 py-12 md:py-16 flex flex-col justify-center px-8 md:px-16"
      >
        <h2 class="text-3xl md:text-5xl font-display mb-4">
          <b>A Culinary 🍴 Journey</b>
        </h2>
        <p class="max-w-lg md:text-lg leading-relaxed">
          At Gunhill Avenue Guesthouse, local flavors dance
          with global inspiration! We blend Zimbabwe's
          finest ingredients with international culinary
          mastery 🍽️.. Every plate tells our story of
          heritage and innovation ✨ Browse our chef's top 4
          selections below. <b>Please note</b>: Our rates
          are bed only, with breakfast and other meals
          available upon request.
        </p>
      </div>

      <!-- Image Column (4/12) - Hidden on Mobile -->
      <div class="hidden lg:block lg:col-span-5 p-2">
        <img
          src="/images/dining/bg.jpg"
          alt="Culinary presentation"
          class="w-full h-full rounded-3xl object-cover"
        />
      </div>
    </div>
    <!-- Desktop Grid -->
    <div class="hidden p-8 lg:grid grid-cols-4 gap-6">
      {#each menuItems[activeCategory] as item}
        <div
          class="group"
          in:fly="{{ y: 20, duration: 400 }}"
        >
          <div
            style="background-color: rgb(255, 255, 255, 0.075);"
            class=" shadow-sm text-white backdrop-blur-md rounded-2xl h-full
                hover:bg-white/15 transition-all duration-300 overflow-hidden"
          >
            <div class="relative">
              <img
                style="height: 200px;"
                src="{item.image}"
                alt="{item.name}"
                class="w-full object-cover transition-transform duration-500
                      group-hover:scale-105"
              />
              {#if item.highlight}
                <div
                  class="absolute top-4 right-4 px-4 py-1.5 bg-black
                      text-black text-sm font-medium rounded-full"
                >
                  Chef's Choice
                </div>
              {/if}
            </div>
            <div class="p-8">
              <h3 class="font-medium text-2xl mb-3">
                {item.name}
              </h3>
              <p
                class="text-stone-300 leading-relaxed max-w-md"
              >
                {item.description}
              </p>
            </div>
          </div>
        </div>
      {/each}
    </div>
  </div>
  <div
    class="flex lg:hidden overflow-x-scroll gap-4 pb-6 no-scrollbar"
  >
    {#each menuItems[activeCategory] as item}
      <div
        style="width: 65vw"
        class="shadow-sm flex-none text-black bg-white backdrop-blur-md rounded-2xl
              hover:bg-white/15 transition-all duration-300 overflow-hidden"
      >
        <div class="relative">
          <img
            src="{item.image}"
            alt="{item.name}"
            style="height: 200px;"
            class="object-cover w-full"
          />
          {#if item.highlight}
            <div
              class="absolute top-4 right-4 px-4 py-1.5 bg-black
                    text-white text-sm font-medium rounded-full"
            >
              Chef's Choice
            </div>
          {/if}
        </div>
        <div class="p-3 py-4">
          <h3 class="font-medium text-xl mb-2">
            {item.name}
          </h3>
          <p
            class="text-stone-300 leading-relaxed max-w-md"
          >
            {item.description}
          </p>
        </div>
      </div>
    {/each}
  </div>
</div>

<style>
  .no-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
</style>
