<section id="about" class="w-full py-8 md:pt-16">
  <div class="mx-auto grid md:grid-cols-2 gap-3 md:gap-16">
    <!-- Left Column -->
    <div class="space-y-4">
      <!-- Title and Intro -->
      <div
        style="background:#29110D;"
        class="p-4 py-10 md:p-16 md:py-16 rounded-3xl"
      >
        <h2
          class="text-3xl max-w-lg md:text-5xl font-display mb-6 text-white font-display mx-4"
        >
          Squeaky clean, quiet & peaceful.
        </h2>
        <p class="text-white text-xl max-w-md mx-4">
          Nestled in Harare's prestigious Gunhill district,
          our boutique 10-room guesthouse offers an intimate
          retreat combining upmarket comfort with unmatched
          convenience.
        </p>
        <!-- Features Grid -->
        <div class="p-8">
          <div class="grid md:grid-cols-1 gap-8">
            <!-- First Column -->
            <div class="flex items-start gap-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                color="#ffffff"
                fill="none"
              >
                <path
                  d="M4 3V5.07692C4 7.07786 4 8.07833 4.14533 8.91545C4.94529 13.5235 8.90656 17.1376 13.9574 17.8674C14.8749 18 16.8068 18 19 18"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M17 21C17.6068 20.4102 20 18.8403 20 18C20 17.1597 17.6068 15.5898 17 15"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <div>
                <p class="text-white">
                  3.5 km away from Sam Levy Mall featuring a
                  modern movie theater and over 10 premium
                  restaurants. Two restaurants within
                  walking distance, including one just 200m
                  away.
                </p>
              </div>
            </div>

            <!-- Second Column -->
            <div class="flex items-start gap-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                color="#ffffff"
                fill="none"
              >
                <path
                  d="M4 3V5.07692C4 7.07786 4 8.07833 4.14533 8.91545C4.94529 13.5235 8.90656 17.1376 13.9574 17.8674C14.8749 18 16.8068 18 19 18"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M17 21C17.6068 20.4102 20 18.8403 20 18C20 17.1597 17.6068 15.5898 17 15"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <div>
                <p class="text-white">
                  Comfortable amenities including
                  climate-controlled rooms, elegant
                  bathrooms, 10kVA solar backup, 18kVA
                  generator backup, and a prolific borehole
                  water supply.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Column with Images and Overlay -->
    <div class="relative mt-8 md:mt-0 h-full">
      <!-- Main Image -->
      <img
        src="images/bath.jpeg"
        alt="Luxury Room"
        class="w-full h-full rounded-3xl object-cover"
      />

      <!-- Overlay Card -->
    </div>
  </div>
</section>
