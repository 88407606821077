<script>
  import { onMount, tick } from 'svelte';
  import { fade, slide } from 'svelte/transition';

  $: isOpen = false;
  $: currentRoom = null;
  $: currentImageIndex = 0;

  let roomImages = [];

  function truncateText(text, length) {
    // Return original text if it's shorter than the specified length
    if (text.length <= length) return text;

    // Truncate at the last complete word before length
    const truncated = text.substring(0, length);
    const lastSpace = truncated.lastIndexOf(' ');

    // If no space found, just truncate at length
    if (lastSpace === -1) return truncated + '...';

    // Return up to the last complete word with ellipsis
    return truncated.substring(0, lastSpace) + '...';
  }

  function nextImage() {
    currentImageIndex =
      (currentImageIndex + 1) % roomImages.length;
  }

  function prevImage() {
    currentImageIndex =
      (currentImageIndex - 1 + roomImages.length) %
      roomImages.length;
  }

  const rooms = [
    {
      id: 1,
      name: 'Deluxe King - Room 1',
      images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg'],
      description:
        'Unwind in this elegant ground floor retreat featuring a sumptuous king-size bed and a luxurious bathroom with combination shower and bathtub. Perfect for both business travelers and couples seeking comfort with style.',
      beds: 1,
      amenities: [
        'air-conditioning',
        'smart-tv',
        'netflix',
        'youtube',
        'bar fridge',
        'kettle',
        'desk',
        'cupboards',
        'safe',
        'smart-lock',
        'well-lit work desk',
      ],
      price: 'US$65',
      size: '20m²',
      location: 'Ground Floor',
    },
    {
      id: 2,
      name: 'Executive Suite - Room 2',
      images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg'],
      description:
        'Experience ultimate luxury in our crown jewel suite, adorned with handcrafted teak wood and designer furniture. Featuring an indulgent standalone bathtub, separate rain shower, and a sophisticated living area. Your private urban sanctuary awaits.',
      beds: 1,
      amenities: [
        'air-conditioning',
        'smart-tv',
        'netflix',
        'youtube',
        'bar fridge',
        'kettle',
        'desk',
        'cupboards',
        'safe',
        'smart-lock',
        'teak-wood',
        'bean-bag',
      ],
      price: 'US$80',
      size: '40m²',
      location: 'Main Building',
    },
    {
      id: 3,
      name: 'Queen Room 3',
      images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg'],
      description:
        'Discover tranquility in this private courtyard-facing haven, complete with a plush queen bed and spacious bathroom. Set apart from the main building for enhanced privacy and peaceful nights.',
      beds: 1,
      amenities: [
        'air-conditioning',
        'smart-tv',
        'netflix',
        'youtube',
        'bar fridge',
        'kettle',
        'desk',
        'safe',
        'smart-lock',
        'well-lit work desk',
      ],
      price: 'US$65',
      size: '20m²',
      location: 'External Ground Floor',
    },
    {
      id: 4,
      name: 'Spacious King - Room 4',
      images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg'],
      description:
        'Elevate your stay in our first-floor sanctuary featuring a majestic king-size bed, and an expansive shower space. Two stylish leather chairs create the perfect spot for evening relaxation.',
      beds: 1,
      amenities: [
        'air-conditioning',
        'smart-tv',
        'netflix',
        'youtube',
        'bar fridge',
        'kettle',
        'desk',
        'cupboards',
        'safe',
        'smart-lock',
        'dual-chairs',
        'well-lit work desk',
      ],
      price: 'US$70',
      size: '20m²',
      location: 'First Floor',
    },
    {
      id: 5,
      name: 'Twin Room 5',
      images: [
        '1.jpg',
        '2.jpg',
        '3.jpg',
        '4.jpg',
        '5.jpg',
        '6.jpg',
        '7.jpg',
        'well-lit work desk',
      ],
      description:
        'Tastefully decorated room in the first floor. Choose between twin beds or an extra-king configuration, perfect for friends traveling together or couples seeking extra sleeping space. First-floor position ensures quiet and privacy.',
      beds: 2,
      amenities: [
        'air-conditioning',
        'smart-tv',
        'netflix',
        'youtube',
        'bar fridge',
        'kettle',
        'desk',
        'smart-lock',
        'well-lit work desk',
      ],
      price: 'US$65-70',
      size: '20m²',
      location: 'First Floor',
    },
    {
      id: 6,
      name: 'Queen Room 6',
      images: [
        '1.jpg',
        '2.jpg',
        '3.jpg',
        '4.jpg',
        '5.jpg',
        '6.jpg',
        '7.jpg',
      ],
      description:
        'Embrace modern elegance in this thoughtfully designed space, featuring a statement Morris chair and invigorating rainfall shower. The perfect blend of comfort and sophistication for the discerning traveler.',
      beds: 1,
      amenities: [
        'air-conditioning',
        'smart-tv',
        'netflix',
        'youtube',
        'bar fridge',
        'kettle',
        'desk',
        'safe',
        'smart-lock',
        'morris-chair',
        'well-lit work desk',
      ],
      price: 'US$65',
      size: '20m²',
      location: 'First Floor',
    },
    {
      id: 7,
      name: 'Garden Queen Room 7',
      images: [
        '1.jpg',
        '2.jpg',
        '3.jpg',
        '4.jpg',
        '5.jpg',
        '6.jpg',
        '7.jpg',
        '8.jpg',
      ],
      description:
        'Your private oasis awaits with this unique ground-floor gem, complete with an exclusive enclosed garden. Perfect for morning coffee or evening relaxation under the stars, while enjoying all our premium indoor amenities.',
      beds: 1,
      amenities: [
        'air-conditioning',
        'smart-tv',
        'netflix',
        'youtube',
        'bar fridge',
        'kettle',
        'desk',
        'smart-lock',
        'private-garden',
        'well-lit work desk',
      ],
      price: 'US$65',
      size: '20m²',
      location: 'Ground Floor',
    },
    {
      id: 8,
      name: 'Self-Catering Suite - Room 8',
      images: [
        '1.jpg',
        '2.jpg',
        '3.jpg',
        '4.jpg',
        '5.jpg',
        '6.jpg',
        '7.jpg',
      ],
      description:
        'Experience the freedom of your own boutique apartment with this sophisticated suite. Featuring a fully-equipped kitchenette and dining area, perfect for extended stays or those who love to cook. Luxury meets independence.',
      beds: 1,
      amenities: [
        'air-conditioning',
        'smart-tv',
        'netflix',
        'youtube',
        'bar fridge',
        'desk',
        'safe',
        'smart-lock',
        'kitchenette',
        'dining-table',
        'well-lit work desk',
      ],
      price: 'US$70',
      size: '28m²',
      location: 'External Ground Floor',
    },
    {
      id: 9,
      name: 'Luxury King Room 9',
      images: [
        '1.jpg',
        '2.jpg',
        '3.jpg',
        '4.jpg',
        '5.jpg',
        '6.jpg',
        '7.jpg',
      ],
      description:
        'Indulge in modern luxury with this premium ground-floor retreat. Featuring a superior king-size bed and upgraded amenities including a 42-inch smart TV. The perfect choice for those who appreciate the finer things in life.',
      beds: 1,
      amenities: [
        'air-conditioning',
        'smart-tv',
        'well-lit work desk',
        'netflix',
        'youtube',
        'bar fridge',
        'kettle',
        'desk',
        'cupboards',
        'smart-lock',
        'dual-chairs',
      ],
      price: 'US$65',
      size: '20m²',
      location: 'External Ground Floor',
    },
    {
      id: 10,
      name: 'Luxury King Room 10',
      images: [
        '1.jpg',
        '2.jpg',
        '3.jpg',
        '4.jpg',
        '5.jpg',
        '6.jpg',
        '7.jpg',
      ],
      description:
        'Welcome to your private sanctuary, where contemporary design meets classic comfort. This premium room offers a peaceful setting away from the main building, featuring deluxe furnishings and a spacious bathroom.',
      beds: 1,
      amenities: [
        'air-conditioning',
        'smart-tv',
        'netflix',
        'youtube',
        'bar fridge',
        'kettle',
        'desk',
        'cupboards',
        'smart-lock',
        'dual-chairs',
        'well-lit work desk',
      ],
      price: 'US$65',
      size: '20m²',
      location: 'External Ground Floor',
    },
  ];

  // Reactive declarations
  $: currentIndex = 1;
  $: visibleSlides = getVisibleSlides(currentIndex);
  let isTransitioning = false;

  function getVisibleSlides(index) {
    const total = rooms.length;
    const prev = (index - 1 + total) % total;
    const next = (index + 1) % total;
    return [prev, index, next];
  }

  async function nextSlide() {
    if (isTransitioning) return;
    isTransitioning = true;
    currentIndex = (currentIndex + 1) % rooms.length;
    await tick();
    setTimeout(() => {
      isTransitioning = false;
    }, 500);
  }

  async function prevSlide() {
    if (isTransitioning) return;
    isTransitioning = true;
    currentIndex =
      (currentIndex - 1 + rooms.length) % rooms.length;
    await tick();
    setTimeout(() => {
      isTransitioning = false;
    }, 500);
  }

  function handleOpenModal(room, index) {
    currentRoom = room;
    roomImages = room.images;

    isOpen = true;
    document.body.style.overflow = 'hidden';
  }

  function handleCloseModal() {
    isOpen = false;
    currentRoom = null;
    currentImageIndex = 0;
    document.body.style.overflow = 'unset';
  }
</script>

<section id="rooms" class="w-full pb-8">
  <div
    class="relative py-16 flex flex-col items-center overflow-hidden"
  >
    <div
      class="relative py-3 z-10 flex flex-col items-center"
    >
      <div
        class="flex items-center justify-center w-full max-w-sm mb-4"
      >
        <hr class="flex-grow border-t border-stone-300" />
        <span
          class="mx-4 text-stone-500 uppercase tracking-widest text-md font-light"
        >
          Discover Comfort
        </span>
        <hr class="flex-grow border-t border-stone-300" />
      </div>
      <!-- Main title with enhanced styling -->

      <h2
        style="color: #4C2617;"
        class="text-3xl md:text-5xl font-display text-stone-900 text-center"
      >
        Our Spacious Rooms
      </h2>
      <p
        class="text-stone-600 text-center max-w-md mt-2 text-base md:text-lg font-light leading-relaxed"
      >
        Where contemporary design meets exceptional comfort,
        creating spaces that inspire and rejuvenate.
      </p>
    </div>
  </div>
  <!-- Desktop Slider -->
  <div class="hidden md:block relative overflow-hidden">
    <div
      class="flex items-center pb-7 justify-center gap-8"
    >
      {#each visibleSlides as slideIndex, i (slideIndex)}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div
          style="cursor: pointer;"
          on:click="{() =>
            handleOpenModal(rooms[slideIndex], slideIndex)}"
          class="transition-all duration-500 ease-in-out {i ===
          1
            ? 'w-full max-w-2xl z-20 opacity-100 scale-100'
            : 'w-full max-w-xl opacity-100 scale-90'}"
        >
          <div
            class="bg-white rounded-3xl overflow-hidden shadow-sm"
          >
            <img
              src="{`/images/rooms/room-${slideIndex + 1}/${rooms[slideIndex].images[0]}`}"
              alt="{rooms[slideIndex].name}"
              class="w-full h-64 object-cover"
            />
            <div class="p-8">
              <div
                class="flex justify-between items-start mb-4"
              >
                <h3
                  class="text-2xl font-display text-stone-900"
                >
                  {rooms[slideIndex].name}
                </h3>
                <p
                  class="text-xl font-display text-stone-700"
                >
                  {rooms[slideIndex].price}
                  <span class="text-sm text-stone-500"
                    >/night</span
                  >
                </p>
              </div>
              <p
                style="min-height: 40px;"
                class="text-stone-600 mb-6 line-clamp-2"
              >
                {truncateText(
                  rooms[slideIndex].description,
                  120,
                )}
              </p>
              <div class="flex items-center gap-6">
                <div class="flex items-center gap-2">
                  <svg
                    class="w-5 h-5 text-stone-700"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      d="M2 4v16M2 8h18a2 2 0 0 1 2 2v10M2 16h20"
                    ></path>
                  </svg>
                  <span class="text-stone-600">
                    {rooms[slideIndex].beds} Bed{rooms[
                      slideIndex
                    ].beds > 1
                      ? 's'
                      : ''}
                  </span>
                </div>
                <div class="flex items-center gap-2">
                  <span class="text-stone-600"
                    >{rooms[slideIndex].size}</span
                  >
                </div>
              </div>
              <div class="flex gap-4 mt-6">
                {#each rooms[slideIndex].amenities as amenity}
                  <div class="text-stone-600">
                    {#if amenity === 'wifi'}
                      <svg
                        class="w-5 h-5"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          d="M12 20h.01M8.5 16.5a5 5 0 017 0M5 12.5a10 10 0 0114 0M1.5 8.5a15 15 0 0121 0"
                        ></path>
                      </svg>
                    {:else if amenity === 'tv'}
                      <svg
                        class="w-5 h-5"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <rect
                          x="2"
                          y="7"
                          width="20"
                          height="15"
                          rx="2"
                          ry="2"
                        ></rect>
                        <path d="M17 2l-5 5-5-5"></path>
                      </svg>
                    {:else if amenity === 'coffee'}
                      <svg
                        class="w-5 h-5"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          d="M17 8h1a4 4 0 110 8h-1M3 8h14v9a4 4 0 01-4 4H7a4 4 0 01-4-4V8zM6 2v4M10 2v4M14 2v4"
                        ></path>
                      </svg>
                    {:else if amenity === 'bath'}
                      <svg
                        class="w-5 h-5"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          d="M4 12h16a1 1 0 011 1v3a4 4 0 01-4 4H7a4 4 0 01-4-4v-3a1 1 0 011-1zM6 12V5a2 2 0 012-2h3v2.25"
                        ></path>
                      </svg>
                    {/if}
                  </div>
                {/each}
              </div>
            </div>
          </div>
        </div>
      {/each}
    </div>

    <!-- Navigation Buttons -->
    <button
      on:click="{() => prevSlide()}"
      class="absolute left-0 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white p-2 rounded-full shadow-lg transition-all"
    >
      <svg
        color="black"
        class="w-6 h-6 text-stone-700"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
      >
        <path d="M15 19l-7-7 7-7"></path>
      </svg>
    </button>
    <button
      on:click="{() => nextSlide()}"
      class="absolute right-0 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white p-2 rounded-full shadow-lg transition-all"
    >
      <svg
        color="black"
        class="w-6 h-6 text-stone-700"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
      >
        <path d="M9 5l7 7-7 7"></path>
      </svg>
    </button>
  </div>

  <!-- Mobile Slider -->
  <div class="md:hidden">
    <div class="relative">
      <!-- svelte-ignore missing-declaration -->
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div
        class="overflow-hidden rounded-3xl pointer"
        in:fade="{{ duration: 300 }}"
        out:fade="{{ duration: 300 }}"
      >
        <div
          style="cursor: pointer;"
          on:click="{() =>
            handleOpenModal(rooms[currentIndex])}"
        >
          <img
            src="/images/rooms/room-{currentIndex +
              1}/{rooms[currentIndex].images[0]}"
            alt="{rooms[currentIndex].name}"
            class="w-full h-64 object-cover"
          />
        </div>
        <div
          style="cursor: pointer;"
          on:click="{() =>
            handleOpenModal(rooms[currentIndex])}"
          class="p-6 bg-white"
        >
          <div
            class="flex justify-between items-center gap-1 mb-4"
          >
            <h3
              class="text-md md:text-xl font-display text-stone-900"
            >
              <b>{rooms[currentIndex].name}</b>
            </h3>
            <p class="text-md font-display text-stone-700">
              <b>{rooms[currentIndex].price}</b>
              <span class="text-sm text-stone-500"
                >/night</span
              >
            </p>
          </div>
          <p class="text-stone-600 text-sm mb-4">
            {rooms[currentIndex].description}
          </p>
          <div class="flex items-center gap-4 mb-4">
            <div class="flex items-center gap-2">
              <svg
                class="w-4 h-4 text-stone-700"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  d="M2 4v16M2 8h18a2 2 0 0 1 2 2v10M2 16h20"
                ></path>
              </svg>
              <span class="text-sm text-stone-600">
                {rooms[currentIndex].beds} Bed{rooms[
                  currentIndex
                ].beds > 1
                  ? 's'
                  : ''}
              </span>
            </div>
            <div class="flex items-center gap-2">
              <span class="text-sm text-stone-600"
                >{rooms[currentIndex].size}</span
              >
            </div>
          </div>
          <div class="flex gap-3">
            {#each rooms[currentIndex].amenities as amenity}
              <div class="text-stone-600">
                {#if amenity === 'wifi'}
                  <svg
                    class="w-4 h-4"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      d="M12 20h.01M8.5 16.5a5 5 0 017 0M5 12.5a10 10 0 0114 0M1.5 8.5a15 15 0 0121 0"
                    ></path>
                  </svg>
                {:else if amenity === 'tv'}
                  <svg
                    class="w-4 h-4"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <rect
                      x="2"
                      y="7"
                      width="20"
                      height="15"
                      rx="2"
                      ry="2"
                    ></rect>
                    <path d="M17 2l-5 5-5-5"></path>
                  </svg>
                {:else if amenity === 'coffee'}
                  <svg
                    class="w-4 h-4"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      d="M17 8h1a4 4 0 110 8h-1M3 8h14v9a4 4 0 01-4 4H7a4 4 0 01-4-4V8zM6 2v4M10 2v4M14 2v4"
                    ></path>
                  </svg>
                {:else if amenity === 'bath'}
                  <svg
                    class="w-4 h-4"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      d="M4 12h16a1 1 0 011 1v3a4 4 0 01-4 4H7a4 4 0 01-4-4v-3a1 1 0 011-1zM6 12V5a2 2 0 012-2h3v2.25"
                    ></path>
                  </svg>
                {/if}
              </div>
            {/each}
          </div>
        </div>
        <div
          class="absolute z-16 top-16 left-0 right-0 flex items-center justify-between py-4 px-4"
        >
          <button
            on:click="{() => prevSlide()}"
            class="bg-white shadow-sm border-none hover:bg-white p-2 rounded-full transition-al"
          >
            <svg
              class="w-6 h-6 text-black"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
            >
              <path d="M15 19l-7-7 7-7"></path>
            </svg>
          </button>
          <button
            on:click="{() => nextSlide()}"
            class="bg-white shadow-sm border-none hover:bg-white p-2 rounded-full transition-all"
          >
            <svg
              class="w-6 h-6 text-black"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
            >
              <path d="M9 5l7 7-7 7"></path>
            </svg>
          </button>
        </div>
      </div>

      <div class="flex justify-center mt-4">
        <div class="flex items-center gap-2">
          {#each rooms as _, index}
            <div
              class="w-2 h-2 rounded-full transition-all {currentIndex ===
              index
                ? 'bg-stone-700 w-4'
                : 'bg-stone-300'}"
            ></div>
          {/each}
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  {#if isOpen && currentRoom}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div
      transition:fade="{{ duration: 200 }}"
      class="fixed inset-0 z-50 flex items-center justify-center p-5"
    >
      <div
        on:click="{handleCloseModal}"
        class="bg-black bg-opacity-60 fixed inset-0"
      ></div>
      <div
        style="height: 92vh;"
        transition:slide="{{ duration: 300 }}"
        class="bg-white relative w-full max-w-6xl rounded-3xl overflow-scroll lg:overflow-hidden shadow-2xl flex flex-col lg:flex-row"
      >
        <!-- Image Section Desktop -->
        <div
          class="lg:w-1/2 fixed top-0 left-0 bottom-0 g:h-full lg:flex-shrink-0 hidden lg:flex relative"
        >
          <img
            src="/images/rooms/room-{currentRoom.id}/{roomImages[
              currentImageIndex
            ]}"
            alt="Room view {currentImageIndex + 1}"
            class="w-full h-full object-cover"
          />

          <div
            class="absolute inset-y-0 left-0 right-0 flex items-center justify-between px-4"
          >
            <button
              on:click="{prevImage}"
              class="bg-white/90 p-2.5 rounded-full hover:bg-white transition-colors"
            >
              <svg
                class="w-6 h-6"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
              >
                <path d="M15 19l-7-7 7-7"></path>
              </svg>
            </button>
            <button
              on:click="{nextImage}"
              class="bg-white/90 p-2.5 rounded-full hover:bg-white transition-colors"
            >
              <svg
                class="w-6 h-6"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
              >
                <path d="M9 5l7 7-7 7"></path>
              </svg>
            </button>
          </div>

          <div
            class="absolute bottom-6 left-1/2 -translate-x-1/2 flex gap-2 bg-black/20 rounded-full p-2"
          >
            {#each roomImages as _, i}
              <button
                on:click="{() => (currentImageIndex = i)}"
                class="w-2 h-2 rounded-full transition-all duration-300
              {currentImageIndex === i
                  ? 'w-6 bg-white'
                  : 'bg-white/60 hover:bg-white/80'}"
              ></button>
            {/each}
          </div>
          <button
            on:click="{handleCloseModal}"
            class="absolute left-4 top-4 bg-white rounded-full p-2.5 shadow-lg hover:bg-gray-50 transition-colors"
          >
            <svg
              class="w-6 h-6"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
            >
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>

        <!-- Image Section Mobile -->
        <div class="sticky top-0 lg:hidden">
          <img
            style="height: 45vh;"
            src="/images/rooms/room-{currentRoom.id}/{roomImages[
              currentImageIndex
            ]}"
            alt="Room view {currentImageIndex + 1}"
            class="w-full h-full object-cover"
          />

          <button
            on:click="{handleCloseModal}"
            class="absolute z-90 left-4 top-4 z-50 bg-white rounded-full p-2.5 shadow-lg hover:bg-gray-50 transition-colors"
          >
            <svg
              class="w-6 h-6"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
            >
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>

          <div
            class="absolute inset-y-0 left-0 right-0 flex items-center justify-between px-4"
          >
            <button
              on:click="{prevImage}"
              class="bg-white/90 p-2.5 rounded-full hover:bg-white transition-colors"
            >
              <svg
                class="w-6 h-6"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
              >
                <path d="M15 19l-7-7 7-7"></path>
              </svg>
            </button>
            <button
              on:click="{nextImage}"
              class="bg-white/90 p-2.5 rounded-full hover:bg-white transition-colors"
            >
              <svg
                class="w-6 h-6"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
              >
                <path d="M9 5l7 7-7 7"></path>
              </svg>
            </button>
          </div>

          <div
            class="absolute bottom-6 left-1/2 -translate-x-1/2 flex gap-2 bg-black/20 rounded-full p-2"
          >
            {#each roomImages as _, i}
              <button
                on:click="{() => (currentImageIndex = i)}"
                class="w-2 h-2 rounded-full transition-all duration-300
              {currentImageIndex === i
                  ? 'w-6 bg-white'
                  : 'bg-white/60 hover:bg-white/80'}"
              ></button>
            {/each}
          </div>
        </div>

        <!-- Content Section with Overlay -->
        <div
          class="flex-1 lg:overflow-scroll relative bg-white"
        >
          <div
            class="absolute inset-x-0 -top-6 h-12 bg-white rounded-t-3xl"
          ></div>
          <div
            class="flex-1 flex flex-col p-8 overflow-y-auto relative"
          >
            <div class="flex-1">
              <div
                class="flex justify-between items-start mb-6"
              >
                <h2
                  class="text-xl md:text-4xl font-display text-stone-900"
                >
                  <b>{currentRoom.name}</b>
                </h2>
                <p
                  class="text-sm md:text-2xl md:text-3xl font-display text-stone-900"
                >
                  {currentRoom.price}<span
                    class="text-sm text-stone-500 ml-1"
                    >/night</span
                  >
                </p>
              </div>

              <p
                class="text-lg text-stone-600 mb-8 leading-relaxed"
              >
                {currentRoom.description}
                <br />
                <span>
                  <p class="text-sm text-gray-600">
                    Please note: Rates are bed only.
                  </p></span
                >
              </p>

              <div class="grid grid-cols-2 gap-8 mb-8">
                <div class="flex items-center gap-3">
                  <div class="p-3 bg-stone-100 rounded-lg">
                    <svg
                      class="w-6 h-6 text-stone-700"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        d="M2 4v16M2 8h18a2 2 0 0 1 2 2v10M2 16h20"
                      ></path>
                    </svg>
                  </div>
                  <div>
                    <p class="text-sm text-stone-500">
                      Beds
                    </p>
                    <p class="text-stone-900 font-medium">
                      {currentRoom.beds}
                    </p>
                  </div>
                </div>
                <div class="flex items-center gap-3">
                  <div class="p-3 bg-stone-100 rounded-lg">
                    <svg
                      class="w-6 h-6 text-stone-700"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        d="M21 21v-2a4 4 0 0 0-4-4H7a4 4 0 0 0-4 4v2M5 3h14a2 2 0 0 1 2 2v6H3V5a2 2 0 0 1 2-2z"
                      ></path>
                    </svg>
                  </div>
                  <div>
                    <p class="text-sm text-stone-500">
                      Room Size
                    </p>
                    <p class="text-stone-900 font-medium">
                      {currentRoom.size}
                    </p>
                  </div>
                </div>
              </div>

              <div
                class="border-t border-b border-stone-200 py-8 mb-8"
              >
                <h3
                  class="text-lg font-medium text-stone-900 mb-4"
                >
                  Room Amenities
                </h3>
                <div class="grid grid-cols-2 gap-4">
                  {#each currentRoom.amenities as amenity}
                    <div class="flex items-center gap-3">
                      <div
                        class="p-2 bg-stone-100 rounded-lg"
                      >
                        {#if amenity === 'wifi'}
                          <svg
                            class="w-5 h-5 text-stone-700"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                          >
                            <path
                              d="M12 20h.01M8.5 16.5a5 5 0 017 0M5 12.5a10 10 0 0114 0M1.5 8.5a15 15 0 0121 0"
                            ></path>
                          </svg>
                        {:else if amenity === 'tv'}
                          <svg
                            class="w-5 h-5 text-stone-700"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                          >
                            <rect
                              x="2"
                              y="7"
                              width="20"
                              height="15"
                              rx="2"
                              ry="2"
                            ></rect>
                            <path d="M17 2l-5 5-5-5"></path>
                          </svg>
                        {:else if amenity === 'coffee'}
                          <svg
                            class="w-5 h-5 text-stone-700"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                          >
                            <path
                              d="M17 8h1a4 4 0 110 8h-1M3 8h14v9a4 4 0 01-4 4H7a4 4 0 01-4-4V8zM6 2v4M10 2v4M14 2v4"
                            ></path>
                          </svg>
                        {:else if amenity === 'bath'}
                          <svg
                            class="w-5 h-5 text-stone-700"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                          >
                            <path
                              d="M4 12h16a1 1 0 011 1v3a4 4 0 01-4 4H7a4 4 0 01-4-4v-3a1 1 0 011-1zM6 12V5a2 2 0 012-2h3v2.25"
                            ></path>
                          </svg>
                        {/if}
                      </div>
                      <span
                        class="text-stone-700 capitalize"
                        >{amenity}</span
                      >
                    </div>
                  {/each}
                </div>
              </div>
            </div>

            <a
              style="text-decoration: none; color:white;"
              href="https://book.nightsbridge.com/37468"
              class="w-full text-center bg-black outline-none border-none ring-2 ring-stone-500 ring-offset-2 text-white py-4 rounded-full font-medium text-lg
           bg-stone-800 transition-colors shadow-lg hover:shadow-xl
           "
            >
              Book Now
            </a>
          </div>
        </div>
      </div>
    </div>
  {/if}
</section>

<style>
  .transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(
      0.4,
      0,
      0.2,
      1
    );
    transition-duration: 500ms;
  }
</style>
